import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./unlock-phone.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Link } from "react-router-dom";
import CheckoutForm from "../stripe/CheckoutForm";
import { useLoading } from "../../_shared/LoadingContext";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { ToastContainer, toast } from "react-toastify";
import Skeleton from "@mui/material/Skeleton";
import { CopyToClipboard } from "react-copy-to-clipboard";
// for calculator
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import { currencyExchange } from "../../_services/unlockPhone";
import { useLocation } from 'react-router-dom';
import { Tab, Tabs } from "react-bootstrap";
import 'react-loading-skeleton/dist/skeleton.css';
import requests from "../../_services/httpServices"; // Import the updated httpService

const UnlockPhone = ({ }) => {
  const location = useLocation();
  // const { formValue, productData } = location.state;
  const { formValue, productData } = JSON.parse(localStorage.getItem("state"))
  // console.log('location.state: ', location.state);
  // console.log('location: ', location);
  console.log('productData: ', productData);
  console.log('formValue: ', formValue);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguageCode = cookies.get("i18next") || "en";
  let website;
  let trustboxlang;
  if (currentLanguageCode === "en") {
    website = "https://www.trustpilot.com/review/www.unlockriver.com";
    trustboxlang = "en-US";
    console.log('from UnlockPhone')
  } else {
    trustboxlang = "es-ES";
    website = "https://es.trustpilot.com/review/www.unlockriver.com";
    console.log('from UnlockPhone')

  }
  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY);
  const [clientSecret, setClientSecret] = useState(null);
  const [selected, setSelected] = useState("");
  const { startLoading, stopLoading } = useLoading();
  const [customer, setCustomer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState("00.000");
  const [amount, setAmount] = useState(`${productData[0]?.price}`);
  const [currency, setCurrency] = useState("2781");
  const [selectedOption, setSelectedOption] = useState({
    value: "1",
    label: "BTC",
  });
  const [key, setKey] = useState('bitcoin');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log(typeof amount)
    currencyExchange(amount, selectedOption.value, currency).then((res) => {
      console.log(res);
      setData(res?.price);
    });
  }, []);

  const fetchClientSecret = async () => {
    console.log("fetchClientSecret: ", "!!!!!!!!!!!!!!!!!!!!!!!!");

    try {
      // Start loading state
      setLoading(true);

      // Make the POST request using httpService
      const response = await requests.post("/order/create-payment-intent", {
        product: productData[0],
        customer: formValue,
        languageId: currentLanguageCode,
      });

      const { clientSecret, customer } = response;

      // Update state with the response data
      setClientSecret(clientSecret);
      setCustomer(customer);
    } catch (error) {
      console.error("Error in fetchClientSecret:", error);
    } finally {
      // Stop loading state
      setLoading(false);
      stopLoading();
    }
  };

  const PaymentFormSkeleton = () => {
    return (
      <div className="payment-form-skeleton">
        {/* Back button */}
        <Skeleton width={80} height={25} style={{ marginBottom: '20px' }} />

        {/* Heading */}
        <Skeleton width={120} height={30} style={{ marginBottom: '10px' }} />

        {/* Subheading */}
        <Skeleton width={80} height={25} style={{ marginBottom: '20px' }} />

        {/* Billing Name Field */}
        <Skeleton height={45} style={{ marginBottom: '20px', borderRadius: '4px' }} />

        {/* Card Information Fields */}
        <Skeleton height={45} style={{ marginBottom: '15px', borderRadius: '4px' }} />

        {/* Expiration Date and Security Code */}
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
          <Skeleton height={45} width="48%" style={{ borderRadius: '4px' }} />
          <Skeleton height={45} width="48%" style={{ borderRadius: '4px' }} />
        </div>

        {/* Country Dropdown */}
        <Skeleton height={40} style={{ marginTop: '20px', marginBottom: '30px', borderRadius: '4px' }} />

        {/* Submit Button */}
        <Skeleton height={45} width="100%" style={{ borderRadius: '4px' }} />
      </div>
    );
  };
  const bitcoinOrder = async () => {
    try {
      startLoading();

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/order/bitcoinOrder`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            product: productData[0],
            customer: formValue,
            languageId: currentLanguageCode,
          }),
        }
      );

      // Check if the response is OK
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.message) {
          toast.success(responseData.message);
          localStorage.setItem('orderDetails', JSON.stringify({ order: responseData.orderId }));
          console.log('addOrderResult.orderId: ', responseData.orderId);
          await localStorage.setItem("order", JSON.stringify({ order: responseData?.orderId }))

          navigate("/success");
        }
      } else {
        // If response is not OK, throw an error
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error during bitcoin order:", error);
      toast.error("Error during bitcoin order:", error);
    } finally {
      stopLoading();
    }
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const selectPaymentMethod = async (type) => {
    console.log(clientSecret);
    setSelected(type);
    if (!clientSecret && type === "card" && Object.keys(formValue).length > 0) {
      await fetchClientSecret();
    } else if (type === "bitcoin" && Object.keys(formValue).length > 0) {
      openModal();
      // await bitcoinOrder();
    }
  };
  const options = [
    { value: "1", label: "BTC" },
    { value: "5426", label: "SOL" },
    { value: "1027", label: "ETH" },
  ];

  const handleCurrencyChange = (data, handleChange) => {
    console.log(data);
    setSelectedOption(data);
    handleChange("convert_id")(data.value);
    currencyExchange(amount, data.value, currency).then((res) => {
      console.log(res);
      setData(res?.price);
    });
  };
  const handleAmtChange = (data, handleChange) => {
    console.log(data.target.value);
    setAmount(data.target.value);
    handleChange("amount")(data.target.value);
    if (parseInt(data.target.value) > 0) {
      currencyExchange(data.target.value, selectedOption.value, currency).then(
        (res) => {
          console.log(res);
          setData(res?.price);
        }
      );
    }
  };
  const inputs = document.querySelectorAll(".menu-input");
  const contents = document.querySelectorAll(".content");
  const menu = document.querySelector("#menu ul");

  inputs.forEach((input, index) => {
    input.addEventListener("change", function () {
      menu.classList.add("hide-menu");
      contents.forEach(content => content.classList.remove("show-content"));
      contents[index].classList.add("show-content");
    });
  });

  contents.forEach((content, index) => {
    const backBtn = content.querySelector("a");
    backBtn.addEventListener("click", function (e) {
      e.preventDefault();
      contents[index].classList.remove("show-content");
      menu.classList.remove("hide-menu");
      inputs[index].checked = false; // Uncheck the input
    });
  });

  const getDeliveryTime = () => {
    const productId = productData[0]?.product_id;
    const manufacturer = formValue?.manufacturer;
  
    if (productId === 4) {
      return manufacturer === "Samsung" ? "8 business hours" : "1 business day";
    }
  
    // if (productId === 5) return "8 hrs";
    if (productId === 5) return "8 hrs";
  
    if ([1, 2, 3].includes(productId)) return t("Reviewyop3");
  
    return t("Reviewyop2");
  };

  return (
    <div>
      <div className="container-fluid p-0 bg-light-conatiner">
        <div className="row py-0 px-md-5 m-0 gap-4 justify-content-center">
          <div className="col-md-12 col-lg-4 py-md-5 pt-3 pb-0">
            <div id="menu">
              <ul>
                <Link className="text-decoration-none text-dark" to={"/unlock-phone"}><i className="fa fa-chevron-left text-dark"></i> {t("Reviewback")}</Link>
                <h5 className="pt-3 fw-semibold pb-3">{t("Payment")}</h5>
                {/* <p>{t("Paymentp1")}</p> */}
                {/* <li onClick={() => selectPaymentMethod("paypal")}>
                  <input type="radio" name="menu-item" id="item-1" className="menu-input" />
                  <label className="d-flex justify-content-between" for="item-1">
                    <div>
                      <img className="me-3" src="/images/payment1.png" alt="Payment" width={'80'} />PayPal
                    </div>
                    <div>
                      <i className="fa fa-chevron-right text-dark"></i>
                    </div>

                  </label>
                </li> */}
                <li onClick={() => selectPaymentMethod("card")}>
                  <input type="radio" name="menu-item" id="item-2" className="menu-input" onClick={(e) => e.stopPropagation()} // Prevents the radio button from triggering the parent li's onClick event
                  />
                  <label for="item-2" className="d-flex justify-content-between" >
                    <div>
                      <img className="me-3" src="/images/payment2.png" alt="Payment" width={'80'} />{t("Paymenth1")}
                    </div>
                    <div>
                      <i className="fa fa-chevron-right text-dark"></i>
                    </div>
                  </label>

                </li>
                {/* <li onClick={() => selectPaymentMethod("crypto")}>
                  <input type="radio" name="menu-item" id="item-3" className="menu-input"     onClick={(e) => e.stopPropagation()} // Prevents the radio button from triggering the parent li's onClick event
                  />
                  <label for="item-3" className="d-flex justify-content-between" >
                    <div>
                      <img className="me-3" src="/images/payment3.png" alt="Payment" width={'80'} />{t("Paymenth2")}
                    </div>
                    <div>
                      <i className="fa fa-chevron-right text-dark"></i>
                    </div>
                  </label>
                </li> */}
              </ul>

              <div id="content-1" className="content">
                <a className="text-decoration-none text-dark" href="#" id="back-1" ><i className="fa fa-chevron-left text-dark"></i> {t("Reviewback")}</a>
                <h5 className="pt-3 fw-semibold">{t("Paymenth3")}</h5>
                <h5>{t("Paymentp2")}</h5>

                <form className="pt-3 pt-md-0">
                  {loading ? (
                    <PaymentFormSkeleton />
                  ) : (
                    clientSecret && selected === "card" && (
                      <Elements
                        stripe={stripePromise}
                        options={{
                          clientSecret: clientSecret,
                          locale: currentLanguageCode,
                        }}
                      >
                        <CheckoutForm
                          customer={customer}
                          clientSecret={clientSecret}
                          productData={productData[0]}
                          formValue={formValue}
                        />
                      </Elements>
                    )
                  )}
                </form>

              </div>
              <div id="content-2" className="content">
                <a className="text-decoration-none text-dark" href="#" id="back-2"><i className="fa fa-chevron-left text-dark"></i> Back</a>

                <h5 className="pt-3 fw-semibold">{t("Paymenth4")}</h5>

                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 gap-2" >
                  <Tab eventKey="bitcoin" title="Bitcoin Wallet" className="px-3 pt-3 px-md-0 ">
                    <h5 className="fw-semibold pt-2">{t("Paymentth1")}</h5>
                    <p>{t("Paymenttp1")}</p>
                    <img className="img-fluid" src="/images/Bitcoin-QR-Code-Wallet.png" width={'350'} /><br /><br />
                    <div className="d-flex gap-2">
                      <div className="copy-code form-control rounded p-3">
                        {t("UnpB5")}{" "}
                      </div>
                      <CopyToClipboard
                        text={`bc1qpayjq7tqmd6z0375l5qwyn6aylm73kutne6jj0`}
                      >
                        <Link className="btn-copy-code"
                          onClick={() => {
                            toast.success("Copied!!");
                          }}
                        >
                          {t("Paymenttb1")}
                        </Link>
                      </CopyToClipboard>{" "}
                    </div>
                  </Tab>
                  <Tab eventKey="solana" title="Solana Wallet">
                    <h5 className="fw-semibold pt-2">{t("Paymentth2")}</h5>
                    <p>{t("Paymenttp1")}</p>
                    <img className="img-fluid" src="/images/Ethereum-QR-Wallet.png" width={'350'} /><br /><br />
                    <div className="d-flex gap-2">
                      <div className="copy-code form-control rounded p-3">
                        {t("UnpB5")}{" "}
                      </div>
                      <CopyToClipboard
                        text={`3rFJdLrhg9FgCVsf2rEWFZKXMSG919uqNf69Uw8DPuvh`}
                      >
                        <Link className="btn-copy-code"
                          onClick={() => {
                            toast.success("Copied!!");
                          }}
                        >
                          copy
                        </Link>
                      </CopyToClipboard>{" "}
                    </div>
                  </Tab>
                  <Tab eventKey="ethereum" title="Ethereum Wallet">
                    <h5 className="fw-semibold pt-2">{t("Paymentth3")}</h5>
                    <p>{t("Paymenttp1")}</p>
                    <img className="img-fluid" src="/images/Solana-QR-Wallet.png" width={'350'} /><br /><br />
                    <div className="d-flex gap-2">
                      <div className="copy-code form-control rounded p-3">
                        {t("UnpB5")}{" "}
                      </div>
                      <CopyToClipboard
                        text={`0x9363829e7e06c10fb563f13E224feE691CE84256`}
                      >
                        <Link className="btn-copy-code"
                          onClick={() => {
                            toast.success("Copied!!");
                          }}
                        >
                          copy
                        </Link>
                      </CopyToClipboard>{" "}
                    </div>
                  </Tab>
                </Tabs>

                <div className="continue_bit">
                  <Link onClick={bitcoinOrder}>{t("UnpB10")}</Link>
                </div>
              </div>



            </div>
          </div>
          <div className="col-md-12 col-lg-4 py-md-4 pt-0 mt-0 ps-md-5 pe-md-3 px-4 mt-md-4 mt-md-0 order-details-col">
            <div className="p-md-0 p-0">
              <div className="row p-0 flex-nowrap">
                <h6 className="fw-bold text-secondary lh-base pb-0">{t("Reviewyo")}</h6>
              </div>

              <div className="row border-bottom pt-3 pb-3 flex-md-nowrap">
                <div className="col-md-4 ps-0">
                  {productData[0] ? (
                    <img
                      loading="lazy"
                      className="modal-img shadow img-fluid"
                      alt={""}
                      src={`${process.env.REACT_APP_IMAGE_URL}${productData[0]?.image}`}

                    />
                  ) : (
                    <Skeleton variant="rectangular" width={150} height={150} />
                  )}
                </div>
                <div className="col-md-8 px-0 py-md-0 py-3">
                  <h6 className="fw-semibold lh-base me-3">
                    <h6 className="fw-semibold lh-base me-3">
                      {[1, 2, 3].includes(productData[0]?.product_id) ? (
                        t("UnpV3")
                      ) : [4].includes(productData[0]?.product_id) ? (
                        `${formValue?.manufacturer || ''} ${t("UnpV4")}`
                      ) : (
                        `${t("UnpV1")} ${formValue?.manufacturer || ''} - ${formValue?.model || ''}`
                      )
                      }
                    </h6>

                  </h6>
                  <h6 className="fw-normal lh-base small">
                    <span className="text-secondary">IMEI:</span> {formValue?.imei}
                  </h6>
                  <h6 className="fw-normal lh-base small">
                    <span className="text-secondary">{t("UnpV2")} :</span>{" "}
                    {formValue?.carrier}
                  </h6>
                </div>
              </div>
              <div className="row pt-3 pb-0 custom-our-price">
                <div className="col-md-8 ps-0 pe-0 custom-our-price-right">
                  <h4 className=" text-secondary lh-base pb-0">{t("Reviewyoh1")}</h4>
                  {/* <p className="text-secondary lh-base">
                    {t("UnpC5")} {productData[0].delivery_time}
                  </p> */}
                </div>
                <div className="col-md-4 pe-0 custom-our-price-right">
                  <h4 className=" text-secondary lh-base cus-price-amount pb-2">
                    {productData[0]?.delivery_time}
                  </h4>

                </div>
              </div>
              <div className="row border-bottom pb-2 pt-0 custom-our-price">
                <div className="col-md-8 ps-0 pe-0 custom-our-price-right">
                  <h4 className=" text-secondary lh-base">{t("Reviewyoh2")}</h4>
                </div>
                <div className="col-md-4 pe-0 custom-our-price-right">
                  <h4 className="text-secondary lh-base cus-price-amount">
                  {getDeliveryTime()}
                  </h4>

                </div>
              </div>
              <div className="row border-bottom py-3">
                <div className="col-md-12 p-0">
                  <h6 className="fw-bold lh-base">{t("UnpC1")}</h6>
                  <div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control rounded p-2"
                        placeholder={t("UnpC2")}
                        aria-label="code"
                        aria-describedby="button-addon2"
                      />
                      <button
                        className="btn mt-0 ms-2 unlock-btn rounded text-white py-2 px-4"
                        style={{ background: "#6A5696" }}
                        type="button"
                      >
                        {t("UnpC3")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row border-bottom pt-3 pb-2 custom-our-price">
                <div className="col-md-8 ps-0 pe-0 custom-our-price-right">
                  <h4 className=" text-secondary lh-base pb-2">{t("UnpC4")}</h4>
                  <h4 className=" text-secondary lh-base">{t("Reviewyoh3")}</h4>
                  {/* <h4 className=" text-secondary lh-base">{t("UnpC5")}</h4> */}
                  {/* <p className="text-secondary lh-base">
                    {t("UnpC5")} {productData[0].delivery_time}
                  </p> */}
                </div>
                <div className="col-md-4 pe-0 custom-our-price-right">
                  <h4 className=" text-secondary lh-base cus-price-amount pb-2">
                    ${productData[0]?.price} USD
                  </h4>
                  <h4 className="text-secondary lh-base cus-price-amount">
                    $0
                  </h4>
                  {/* {<h4 className="text-secondary lh-base cus-price-amount">
                    {t("UnpC6")} {productData[0]?.delivery_time}{" "}
                  </h4>} */}
                </div>
              </div>
              <div className="row pt-3 pb-2 custom-our-price">
                <div className="col-md-8 ps-0 pe-0 custom-our-price-right">
                  <h6 className="fw-bold text-dark lh-base pb-2">Total:</h6>

                </div>
                <div className="col-md-4 pe-0 custom-our-price-right">
                  <h6 className="fw-bold text-dark lh-base cus-price-amount pb-2 text-end">
                    ${productData[0]?.price} USD
                  </h6>

                </div>
              </div>
              <div className="row d-flex flex-nowrap pt-3 pb-2 custom-our-price">
                {formValue?.manufacturer !== "Apple" && productData[0]?.product_id !== 5 && productData[0]?.product_id !== 4 &&
                  productData[0]?.product_id !== 3 && productData[0]?.product_id !== 2 && productData[0]?.product_id !== 1 &&
                  <div className="col-12 d-flex flex-nowrap">
                    <img className="me-3" src="/images/helpus.png" width={'20'} height={20} />
                    <p className="text-justify lh-base">{t("Reviewyop21")}</p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog className="dialog dialog-box" open={isModalOpen}>
        <div className="text-center close-btn-cus">
          <span className="float-end mt-3" onClick={closeModal}>
            <img
              loading="lazy"
              src="/images/close-dialog.svg"
              alt="close"
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>

        <DialogTitle className="title">{t("UnpHead")}</DialogTitle>
        <div className="bitcoin_thankyou">
          <p className="bitcoin_thankyou_desc">{t("UnpB1")}</p>
          <ul>
            <li>
              <b>Step 1: </b> {t("UnpB2")}
            </li>
            <b>Step 2: </b> {t("UnpB3")}
            <ul className="bitcoin_thankyou_inner_list ">
              <div className="col-12 col-md-12 col-lg-12 mb-3 mb-md-5 px-md-3 px-0">
                <div className=" bg-white shadow p-4">
                  <div className="row pt-3">
                    <li>
                      <p className="bitcoin_thankyou_desc">
                        {t('UnpB11')}
                      </p>
                      {t('UnpB12')}
                    </li>
                    <Formik
                      initialValues={{
                        amount: 1,
                        convert_id: "1",
                        id: "",
                      }}
                      onSubmit={"handleSubmit"}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                      }) => (
                        <>
                          <Form className="cal_form">
                            <div className="input-group mb-3 border py-1 d-flex justify-content-between">
                              <label
                                className="input-group-text border-0"
                                htmlFor="inputGroupSelect01"
                              >
                                <img
                                  loading="lazy"
                                  src="/images/en.svg"
                                  alt=""
                                />
                                <b>USD</b>
                              </label>
                              <Field
                                type="number"
                                className="form-control border-0 py-2"
                                id="amount"
                                name="amount"
                                value={amount}
                                onChange={(data) => {
                                  handleAmtChange(data, handleChange);
                                }}
                              />
                            </div>

                            <div className="input-group mb-3 border py-1 d-flex justify-content-between">
                              <label
                                className="input-group-text border-0 cripto_label"
                                htmlFor="carrier"
                              >
                                <img
                                  src={`/images/${selectedOption.label}.svg`}
                                  alt=""
                                  style={{ padding: 0 }}
                                />
                              </label>
                              <Select
                                className="form-select-carrier1 border-0"
                                id="convert_id"
                                name="convert_id"
                                options={options}
                                defaultValue={selectedOption}
                                onChange={(data) => {
                                  setSelectedOption(data);
                                  handleCurrencyChange(data, handleChange);
                                }}
                                placeholder={t("FormField1")}
                              />
                            </div>

                            <div className="input-group mb-3 border py-1 d-flex justify-content-between">
                              <Field
                                type="number"
                                className="form-control border-0 py-2"
                                value={data}
                              />
                            </div>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 mb-3 mb-md-5 px-md-3 px-0">
                <div className=" bg-white shadow p-4">
                  <div className="row pt-3">
                    <li>
                      <img
                        loading="lazy"
                        src="/images/BTC.svg"
                        width={20}
                        alt="Bitcoin"
                      />
                      :{t("UnpB4")}
                      <CopyToClipboard
                        text={`bc1qpayjq7tqmd6z0375l5qwyn6aylm73kutne6jj0`}
                      >
                        <Link
                          onClick={() => {
                            toast.success("Copied!!");
                          }}
                        >
                          copy
                        </Link>
                      </CopyToClipboard>{" "}
                      <br />
                      <img
                        loading="lazy"
                        className="p-4"
                        src="/images/1. Bitcoin QR Code Wallet.png"
                        alt="Bitcoin"
                        width={200}
                      />
                    </li>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-12 col-lg-12 mb-3 mb-md-5 px-md-3 px-0">
                <div className=" bg-white shadow p-4">
                  <div className="row pt-3">
                    <li>
                      <img
                        loading="lazy"
                        src="/images/ETH.svg"
                        width={20}
                        alt="Ethereum"
                      />
                      :{t("UnpB5")}{" "}
                      <CopyToClipboard
                        text={`0x9363829e7e06c10fb563f13E224feE691CE84256`}
                      >
                        <Link
                          onClick={() => {
                            toast.success("Copied!!");
                          }}
                        >
                          copy
                        </Link>
                      </CopyToClipboard>{" "}
                      <br />
                      <img
                        loading="lazy"
                        className="p-4"
                        src="/images/2. Ethereum QR Wallet.png"
                        alt="Solana"
                        width={200}
                      />
                    </li>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-12 col-lg-12 mb-3 mb-md-5 px-md-3 px-0">
                <div className=" bg-white shadow p-4">
                  <div className="row pt-3">
                    <li>
                      <img
                        loading="lazy"
                        src="/images/SOL.svg"
                        width={20}
                        alt="Solana"
                      />
                      :{t("UnpB6")}{" "}
                      <CopyToClipboard
                        text={`3rFJdLrhg9FgCVsf2rEWFZKXMSG919uqNf69Uw8DPuvh`}
                      >
                        <Link
                          onClick={() => {
                            toast.success("Copied!!");
                          }}
                        >
                          copy
                        </Link>
                      </CopyToClipboard>{" "}
                      <br />
                      <img
                        loading="lazy"
                        className="p-4"
                        src="/images/3. Solana QR Wallet.png"
                        alt="Solana"
                        width={200}
                      />
                    </li>
                  </div>
                </div>
              </div>
            </ul>
            <li>
              <b>Step 3: </b>
              {t("UnpB7")}
            </li>
          </ul>
          <p>{t("UnpB8")}</p>
          <p>{t("UnpB9")}</p>
          <div className="continue_bit">
            <Link onClick={bitcoinOrder}>{t("UnpB10")}</Link>
          </div>
          <ToastContainer />
        </div>
      </Dialog>


    </div>
  );
};

export default UnlockPhone;
